import { all } from 'redux-saga/effects';

import userSaga from './user';
import settingsSaga from './settings';
import edubotSaga from './edubot';


export default function* rootSaga() {
  yield all([
    userSaga(),
    settingsSaga(),
    edubotSaga()
  ]);
}
