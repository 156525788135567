import _ from 'lodash';

import { EDUBOT, SIGN_OUT } from '../actions/constants';


const initialState = {
  pending: {},
  official: {}
};

export default function user(state = initialState, action) {
  const {
    id,
    category,
    tags,
    activities,
    approved,
    time,
    activity
  } = action;
  const { pending } = state;
  switch (action.type) {
    case EDUBOT.handlers.pending.activities:
    case EDUBOT.handlers.pending.update:
    case EDUBOT.handlers.pending.approve:
      return {
        ...state,
        pending: {
          ..._.get(state, 'pending', {}),
          handling: true
        }
      };
    case EDUBOT.pending.update:
      return {
        ...state,
        pending: {
          activities: {
            ..._.get(state, 'pending.activities', {}),
            ...activities
          },
          handling: false
        }
      };
    case EDUBOT.pending.remove:
      _.unset(pending, `activities.${id}`);
      return {
        ...state,
        pending: {
          ...pending,
          handling: false
        }
      };
    case EDUBOT.pending.approve:
      return {
        ...state,
        pending: {
          activities: {
            ..._.get(state, 'pending.activities', {}),
            [id]: {
              ..._.get(state, `pending.activities.${id}`, {}),
              approved,
              time
            }
          },
          handling: false
        }
      };
    case EDUBOT.handlers.official.activities:
      return {
        ...state,
        official: {
          activities: {},
          handling: true
        }
      };
    case EDUBOT.handlers.official.activity:
      return {
        ...state,
        official: {
          activities: _.get(state, 'official.activities', {}),
          handling: true
        }
      };
    case EDUBOT.official.fetch:
      return {
        ...state,
        official: {
          activities,
          handling: false
        }
      };
    case EDUBOT.official.update:
      return {
        ...state,
        official: {
          activities: _.get(state, 'official.activities', {}),
          editor: activity,
          handling: false
        }
      };
    case EDUBOT.official.search:
      const clone = _.cloneDeep(_.get(state, 'official.activities', {}));
      if (!category || !tags)
        _.unset(clone, id);
      return {
        ...state,
        official: {
          activities: clone,
          editor: _.get(state, 'official.editor'),
          handling: _.get(state, 'official.handling', false)
        }
      };
    case EDUBOT.official.clear:
      return {
        ...state,
        official: {
          activities: _.get(state, 'official.activities', {}),
          handling: false
        }
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
