import {
  takeLatest,
  put,
  call,
  all
} from 'redux-saga/effects';

import { warning } from '../../components/Notification';

import { db } from '../../libs/firebase';
import { USER, SIGN_OUT } from './constants';
import { queryTags, queryPeriods } from './catalogs';


export function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data
  });
}

function* userSignedIn(user) {
  const { email } = user;
  if (email) {
    yield* queryTags();
    yield* queryPeriods();
    const { uid, displayName, photoURL } = user;
    try {
      const ref = db.collection('staffs').doc(email);
      const roles = yield call([ref, ref.get]);
      if (roles.exists) {
        yield* update({
          uid,
          displayName: displayName || email,
          photoURL: photoURL || '',
          email,
          roles: roles.data()
        });
      }
    } catch ({ message }) {
      warning(message);
      yield* update({
        uid,
        displayName: displayName || email,
        photoURL: photoURL || '',
        email
      });
    }
  } else {
    yield* update();
  }
}

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user
});

export const handleUserSignOut = () => ({
  type: SIGN_OUT
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn)
  ]);
}
