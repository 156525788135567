import React, { Component } from 'react';
import {
  Tabs,
  Tab
} from '@blueprintjs/core';

import Official from './Official';
import Pending from './Pending';


export default class Edubot extends Component {
  render() {
    return (
      <div>
        <Tabs
          animate
          id="content"
          renderActiveTabPanelOnly
        >
          <Tab
            id="official"
            title="Official"
            panel={<Official />}
          />
          <Tab
            id="pending"
            title="Pending"
            panel={<Pending />}
          />
        </Tabs>
      </div>
    );
  }
}
