import { combineReducers } from 'redux';

import user from './user';
import catalogs from './catalogs';
import settings from './settings';
import edubot from './edubot';



const rootReducer = combineReducers({
  user,
  catalogs,
  settings,
  edubot
});

export default rootReducer;
