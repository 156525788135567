import _ from 'lodash';

import { USER, SIGN_OUT } from '../actions/constants';


const initialState = {
  handling: true
};

export default function user(state = initialState, action) {
  const {
    displayName,
    email,
    photoURL,
    uid,
    roles
  } = action;
  switch (action.type) {
    case USER.update:
      return {
        displayName: displayName || '',
        email: email || '',
        photoURL: photoURL || '',
        uid: uid || '',
        roles: roles || {},
        handling: false
      };
    case USER.roles:
      return {
        ...state,
        roles: _.get(action, 'roles', {})
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
