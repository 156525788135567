import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import _ from 'lodash';


export default class MultiSelector extends Component {
  handleTagRemove = (tag) => {
    const { onChange, selected } = this.props;
    onChange(_.cloneDeep(_.filter(selected, item => item !== tag)));
  }

  handleItemSelect = (item) => {
    const { value } = item;
    const { selected, onChange } = this.props;
    if (_.indexOf(selected, value) !== -1)
      this.handleTagRemove(value);
    else
      onChange(_.cloneDeep(_.concat(selected, [value])));
  }

  renderItem = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate)
      return null;
    const { active, disabled } = modifiers;
    const { value, text, label } = item;
    const { selected } = this.props;
    return (
      <MenuItem
        key={value}
        active={active}
        icon={(_.indexOf(selected, value) !== -1) ? 'tick' : 'blank'}
        disabled={disabled}
        text={text || value}
        onClick={handleClick}
        label={label || ''}
        shouldDismissPopover={false}
      />
    );
  };

  render() {
    const {
      items,
      selected,
      placeholder,
      onChange
    } = this.props;
    return (
      <MultiSelect
        itemRenderer={this.renderItem}
        noResults={<MenuItem disabled text="Không tìm thấy" />}
        items={items}
        onItemSelect={this.handleItemSelect}
        placeholder={placeholder}
        tagRenderer={value => _.get(_.find(items, { value }), 'text', value)}
        tagInputProps={{
          tagInputProps: () => ({ minimal: true }),
          onRemove: (text) => {
            const found = _.find(items, { text });
            if (found)
              this.handleTagRemove(found.key);
          },
          rightElement: selected.length ? (
            <Button
              icon="cross"
              minimal
              onClick={() => onChange([])}
            />
          ) : undefined
        }}
        selectedItems={selected}
      />
    );
  }
}

MultiSelector.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  onChange: PropTypes.func.isRequired
};

MultiSelector.defaultProps = {
  selected: [],
  placeholder: ''
};
