import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import _ from 'lodash';


export default class Dropdown extends Component {
  renderItem = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate)
      return null;
    const { active, disabled } = modifiers;
    const { value, text, label } = item;
    return (
      <MenuItem
        key={value}
        active={active}
        disabled={disabled}
        text={text || value}
        onClick={handleClick}
        label={label || ''}
      />
    );
  };

  filterItem = (
    query,
    item,
    _index,
    exactMatch
  ) => {
    const { value, text } = item;
    const normalizedText = (text || value).toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch)
      return normalizedText === normalizedQuery;
    return normalizedText.indexOf(normalizedQuery) >= 0;
  };

  getText = () => {
    const { items, value } = this.props;
    const found = _.find(items, ['value', value]);
    return _.get(found, 'text', '');
  }

  render() {
    const {
      items,
      onChange,
      placeholder,
      filterable
    } = this.props;
    return (
      <Select
        filterable={filterable}
        itemRenderer={this.renderItem}
        itemPredicate={this.filterItem}
        noResults={<MenuItem disabled text="Không tìm thấy" />}
        items={items}
        onItemSelect={selected => onChange(selected.value)}
      >
        <Button text={this.getText() || placeholder} rightIcon="caret-down" />
      </Select>
    );
  }
}

Dropdown.propTypes = {
  filterable: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  onChange: PropTypes.func.isRequired
};

Dropdown.defaultProps = {
  filterable: true,
  value: '',
  placeholder: ''
};
