import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Classes,
  Button,
  Intent,
  Tab,
  Tag,
  Tabs,
  Icon,
  Card,
  Elevation,
  FormGroup,
  Checkbox,
} from '@blueprintjs/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFixedColumns,
  Table
} from '@devexpress/dx-react-grid-material-ui';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Parser from 'html-react-parser';

import {
  Waiting,
  warning,
  Input,
  MultiSelector,
} from '../../components';
import Tags from './Tags';
import Editor from './Editor';

import {
  handleUpdatePendingActivity,
  handleApprovePendingActivity,
  handleUpdateActivity,
} from '../../redux/actions/edubot';


function Activity({
  closeHandler,
  id,
  official,
  allowEdit,
  cornerActivity,
}) {
  const dispatch = useDispatch();
  const {
    dark,
    tags: initTags,
    category: initCategory,
    content: initContent,
    detail: initDetail,
    prepare: initPrepare,
    requirement: initRequirement,
    before: initBefore,
    corners: initCorners,
    after: initAfter,
    short: initShort,
    created,
    approved,
    handling,
    catalogs,
  } = useSelector(({
    settings,
    edubot,
    user,
    catalogs: catalogsStore
  }) => ({
    email: _.get(user, 'email', ''),
    roles: _.get(user, 'roles', {}),
    dark: _.get(settings, 'dark', false),
    tags: _.get(edubot, `${official ? 'official' : 'pending'}.activities.${id}.tags`, []),
    category: _.get(edubot, `${official ? 'official' : 'pending'}.activities.${id}.category`, []),
    content: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.content`, ''),
    detail: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.detail`, ''),
    prepare: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.prepare`, ''),
    requirement: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.requirement`, ''),
    before: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.before`, ''),
    corners: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.corners`, []),
    after: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.after`, ''),
    created: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.created`, ''),
    short: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.short`, ''),
    approved: _.get(edubot, `${official ? 'official.editor' : `pending.activities.${id}`}.approved`, ''),
    handling: _.get(edubot, `${official ? 'official' : 'pending'}.handling`, false),
    catalogs: catalogsStore
  }));

  const [category, setCategory] = useState(initCategory);
  const [tags, setTags] = useState(initTags);
  const [showTags, setShowTags] = useState();
  const [before, setBefore] = useState(initBefore);
  const [after, setAfter] = useState(initAfter);
  const [corners, setCorners] = useState(initCorners);
  const [corner, setCorner] = useState();
  const [content, setContent] = useState(initContent);
  const [detail, setDetail] = useState(initDetail);
  const [prepare, setPrepare] = useState(initPrepare);
  const [short, setShort] = useState(initShort);
  const [requirement, setRequirement] = useState(initRequirement);
  const [summary, setSummary] = useState(false);

  const tabs = useMemo(() => {
    const allTabs = [];
    if (cornerActivity) {
      allTabs.push(<Tab
        key="before"
        id="before"
        title="Hoạt động 1: Thoả thuận trước khi chơi"
        panel={(
          <Editor
            title="Thoả thuận trước khi chơi"
            value={before}
            short={_.get(short, 'before', '')}
            onChange={setBefore}
            onShortChange={(newValue) => setShort(prevShort => ({
              ...prevShort,
              before: newValue
            }))}
            allowEdit={allowEdit}
          />
        )}
      />);

      let columns = allowEdit ? [{ name: 'id', title: ' ', width: 100 }] : [];
      columns = [
        ...columns,
        {
          name: 'corner',
          title: 'Góc',
          width: 100,
          wordWrapEnabled: true
        },
        {
          name: 'content',
          title: 'Nội dung',
          wordWrapEnabled: true
        },
        {
          name: 'requirement',
          title: 'Mục đích yêu cầu',
          wordWrapEnabled: true
        },
        {
          title: 'Chuẩn bị',
          name: 'prepare',
          wordWrapEnabled: true
        },
        {
          title: 'Tổ chức hoạt động',
          name: 'detail',
          wordWrapEnabled: true
        }
      ];
      allTabs.push(<Tab
        key="corners"
        id="corners"
        title="Góc"
        panel={(
          <div>
            <Grid
              rows={_.map(corners, (activity, cid) => ({
                id: cid,
                ...activity
              }))}
              columns={columns}
              getRowId={row => row.id}
            >
              {allowEdit ? (
                <DataTypeProvider
                  formatterComponent={({ value, row }) => {
                    if (value === undefined) return null;
                    return (
                      <div>
                        <Icon
                          icon="small-minus"
                          iconSize={16}
                          style={{ cursor: 'pointer', padding: 2 }}
                          intent={Intent.WARNING}
                          onClick={() => setCorners(prevCorners => {
                            prevCorners.splice(row.id, 1);
                            return prevCorners;
                          })}
                        />
                        <Icon
                          icon="edit"
                          iconSize={16}
                          style={{ cursor: 'pointer', padding: 2 }}
                          intent={Intent.PRIMARY}
                          onClick={() => setCorner(_.cloneDeep(row))}
                        />
                      </div>
                    );
                  }}
                  for={['id']}
                />
              ) : null}
              <DataTypeProvider
                formatterComponent={({ column, value, row }) => summary ? Parser(_.get(row, `short.${column.name}`, '')) : Parser(value)}
                for={[
                  'content',
                  'requirement',
                  'prepare',
                  'detail'
                ]}
              />
              <Table
                columnExtensions={_.map(columns, col => ({
                  columnName: col.name,
                  ...col
                }))}
                messages={{ noData: 'Chưa có dữ liệu.' }}
              />
              <TableHeaderRow />
              <TableFixedColumns leftColumns={['id', 'corner']} />
            </Grid>
          </div>
        )}
      />);
      allTabs.push(<Tab
        key="after"
        id="after"
        title="Hoạt động 3: Nhận xét sau khi chơi"
        panel={(
          <Editor
            title="Nhận xét sau khi chơi"
            value={after}
            short={_.get(short, 'after', '')}
            onChange={setAfter}
            onShortChange={(newValue) => setShort(prevShort => ({
              ...prevShort,
              after: newValue
            }))}
            allowEdit={allowEdit}
          />
        )}
      />);
    } else {
      allTabs.push(<Tab
        key="content"
        id="content"
        title="Tên hoạt động*"
        panel={(
          <Editor
            title="Tên hoạt động*"
            value={content}
            short={_.get(short, 'content', '')}
            onChange={setContent}
            onShortChange={(newValue) => setShort(prevShort => ({
              ...prevShort,
              content: newValue
            }))}
            allowEdit={allowEdit}
          />
        )}
      />);
      allTabs.push(<Tab
        key="requirement"
        id="requirement"
        title="Mục đích yêu cầu"
        panel={(
          <Editor
            title="Mục đích yêu cầu"
            value={requirement}
            short={_.get(short, 'requirement', '')}
            onChange={setRequirement}
            onShortChange={(newValue) => setShort(prevShort => ({
              ...prevShort,
              requirement: newValue
            }))}
            allowEdit={allowEdit}
          />
        )}
      />);
      allTabs.push(<Tab
        key="prepare"
        id="prepare"
        title="Chuẩn bị"
        panel={(
          <Editor
            title="Chuẩn bị"
            value={prepare}
            short={_.get(short, 'prepare', '')}
            onChange={setPrepare}
            onShortChange={(newValue) => setShort(prevShort => ({
              ...prevShort,
              prepare: newValue
            }))}
            allowEdit={allowEdit}
          />
        )}
      />);
      allTabs.push(<Tab
        key="detail"
        id="detail"
        title="Tổ chức hoạt động"
        panel={(
          <Editor
            title="Tổ chức hoạt động"
            value={detail}
            short={_.get(short, 'detail', '')}
            onChange={setDetail}
            onShortChange={(newValue) => setShort(prevShort => ({
              ...prevShort,
              detail: newValue
            }))}
            allowEdit={allowEdit}
          />
        )}
      />);
      allTabs.push();
    }
    return allTabs;
  }, [
    cornerActivity,
    corners,
    content,
    requirement,
    detail,
    prepare,
    before,
    after,
    summary,
    short,
    allowEdit
  ]);

  return (
    <Dialog
      style={{ width: '80%' }}
      icon="console"
      title={(
        <>
          {id || 'Thêm mới hoạt động'}
          {created ? (
            <Tag style={{ marginLeft: 5 }} minimal round>
              {created}
            </Tag>
          ) : null}
          {approved ? (
            <Tag
              style={{ marginLeft: 5 }}
              minimal
              round
              rightIcon="tick"
            >
              {approved}
            </Tag>
          ) : null}
        </>
      )}
      onClose={closeHandler}
      isOpen
      className={dark ? 'bp3-dark' : ''}
      canOutsideClickClose={false}
    >
      <div className={Classes.DALOG_BODY} style={{ padding: 10 }}>
        {handling ? <Waiting /> : null}
        <FormGroup
          label="Giờ sinh hoạt"
          helperText={category.length ? '' : 'Chọn ít nhất 1 giờ sinh hoạt'}
          inline
          style={{ marginLeft: 10 }}
          labelInfo="*"
        >
          <MultiSelector
            placeholder="Chọn tất cả"
            selected={category}
            items={_.map(_.get(catalogs, 'periods.data', {}),
              (data, key) => ({
                value: key,
                text: data.label
              }))}
            onChange={setCategory}
          />
        </FormGroup>
        <FormGroup
          inline
          label="Từ khoá"
          helperText={tags.length ? '' : 'Cần phải có ít nhất 1 từ khoá'}
          style={{ marginLeft: 10 }}
        >
          {_.map(tags, tag => (
            <Tag key={tag} style={{ marginRight: 10, marginTop: 5 }} minimal>
              {_.get(catalogs, `tags.data.${tag}.label`)}
            </Tag>
          ))}
          <Tag
            style={{ marginTop: 5 }}
            interactive
            minimal
            intent={Intent.DANGER}
            onClick={() => setShowTags(true)}
          >
            Chọn từ khoá
          </Tag>
        </FormGroup>
        {corner ? (
          <Dialog
            title="Hoạt động góc"
            onClose={() => setCorner()}
            isOpen
            className={dark ? 'bp3-dark' : ''}
            canOutsideClickClose={false}
            style={{ width: '70%' }}
          >
            <div className={Classes.DALOG_BODY} style={{ padding: 10 }}>
              <FormGroup
                label="Tên góc"
                helperText={corner.corner ? '' : 'Không được bỏ trống'}
                labelInfo="*"
              >
                <Input
                  value={_.get(corner, 'corner', '')}
                  onChange={value => setCorner(prevCorner => ({ ...prevCorner, corner: value }))}
                />
              </FormGroup>
              <Card elevation={Elevation.FOUR}>
                <Editor
                  title="Nội dung"
                  value={_.get(corner, 'content', '')}
                  short={_.get(corner, 'short.content', '')}
                  onChange={value => setCorner(prevCorner => ({ ...prevCorner, content: value }))}
                  onShortChange={value => setCorner(prevCorner => ({
                    ...prevCorner,
                    short: {
                      ..._.get(prevCorner, 'short', {}),
                      content: value
                    }
                  }))}
                  allowEdit={allowEdit}
                />
              </Card>
              <Card elevation={Elevation.FOUR}>
                <Editor
                  title="Mục đích yêu cầu"
                  value={_.get(corner, 'requirement', '')}
                  short={_.get(corner, 'short.requirement', '')}
                  onChange={value => setCorner(prevCorner => ({ ...prevCorner, requirement: value }))}
                  onShortChange={value => setCorner(prevCorner => ({
                    ...prevCorner,
                    short: {
                      ..._.get(prevCorner, 'short', {}),
                      requirement: value
                    }
                  }))}
                  allowEdit={allowEdit}
                />
              </Card>
              <Card elevation={Elevation.FOUR}>
                <Editor
                  title="Chuẩn bị"
                  value={_.get(corner, 'prepare', '')}
                  short={_.get(corner, 'short.prepare', '')}
                  onChange={value => setCorner(prevCorner => ({ ...prevCorner, prepare: value }))}
                  onShortChange={value => setCorner(prevCorner => ({
                    ...prevCorner,
                    short: {
                      ..._.get(prevCorner, 'short', {}),
                      prepare: value
                    }
                  }))}
                  allowEdit={allowEdit}
                />
              </Card>
              <Card elevation={Elevation.FOUR}>
                <Editor
                  title="Tổ chức hoạt động"
                  value={_.get(corner, 'detail', '')}
                  short={_.get(corner, 'short.detail', '')}
                  onChange={value => setCorner(prevCorner => ({ ...prevCorner, detail: value }))}
                  onShortChange={value => setCorner(prevCorner => ({
                    ...prevCorner,
                    short: {
                      ..._.get(prevCorner, 'short', {}),
                      detail: value
                    }
                  }))}
                  allowEdit={allowEdit}
                />
              </Card>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  rightIcon="arrow-right"
                  text="Tiếp tục"
                  intent={Intent.PRIMARY}
                  onClick={() => {
                    if (!corner.corner) {
                      warning('Tên góc không được bỏ trống.');
                      return;
                    }
                    if (!corner.content) {
                      warning('Nội dung góc không được bỏ trống.');
                      return;
                    }
                    if (corner.id === corners.length) {
                      setCorners(prevCorners => ([
                        ...prevCorners,
                        {
                          corner: corner.corner,
                          content: corner.content,
                          requirement: corner.requirement,
                          detail: corner.detail,
                          prepare: corner.prepare,
                          short: corner.short,
                        }
                      ]));
                    }
                    else {
                      setCorners(prevCorners => {
                        const clone = _.cloneDeep(prevCorners);
                        const { id: cid, ...rest } = corner;
                        clone[cid] = rest;
                        return clone;
                      });
                      setCorner();
                    }
                  }}
                />
              </div>
            </div>
          </Dialog>
        ) : null}
        {showTags ? (
          <Tags
            closeHandler={() => setShowTags()}
            updateHandler={setTags}
            selected={tags}
          />
        ) : null}
        <Tabs animate>{tabs}</Tabs>
      </div>
      {allowEdit ? (
        <div className={Classes.DIALOG_FOOTER}>
          {cornerActivity ? (
            <div style={{ float: 'left', display: 'flex' }}>
              <Checkbox
                style={{ marginTop: 10, marginRight: 10 }}
                label="Tóm tắt"
                checked={summary}
                onChange={() => setSummary(prevSummary => !prevSummary)}
              />
              {allowEdit ? (
                <Button
                  icon="add"
                  text="Góc"
                  intent={Intent.SUCCESS}
                  onClick={() => setCorner({
                    id: corners.length,
                    corner: '',
                    content: '',
                    detail: '',
                    requirement: '',
                    prepare: '',
                    short: {},
                  })}
                />
              ) : null}
            </div>
          ) : null}
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {!official ? (
              <Button
                icon="confirm"
                text={approved ? 'Bỏ duyệt' : 'Duyệt'}
                intent={approved ? Intent.WARNING : Intent.SUCCESS}
                onClick={() => dispatch(handleApprovePendingActivity(id,
                  !!approved,
                  closeHandler))}
              />
            ) : null}
            <Button
              icon="floppy-disk"
              text="Cập nhật"
              intent={Intent.PRIMARY}
              onClick={() => {
                if (!category.length || !tags.length) {
                  warning('Hoạt động phải được phân loại theo giờ sinh hoạt và từ khoá.');
                  return;
                }
                if (cornerActivity) {
                  if (_.some(corners, c => !c.content)) {
                    warning('Hoạt động góc không hợp lệ.');
                    return;
                  }
                } else if (!content) {
                  warning('Tên hoạt động không được bỏ trống.');
                  return;
                }
                const updateContent = {
                  tags,
                  category,
                  created,
                };
                updateContent.short = short;
                if (cornerActivity) {
                  updateContent.before = before;
                  updateContent.after = after;
                  updateContent.corners = corners;
                } else {
                  updateContent.content = content;
                  updateContent.prepare = prepare;
                  updateContent.detail = detail;
                  updateContent.requirement = requirement;
                }
                if (official) dispatch(handleUpdateActivity(id, updateContent, closeHandler));
                else dispatch(handleUpdatePendingActivity(id, updateContent, closeHandler));
              }}
            />
          </div>
        </div>
      ) : null}
    </Dialog>
  );
}

Activity.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  official: PropTypes.bool,
  cornerActivity: PropTypes.bool,
  allowEdit: PropTypes.bool,
};

Activity.defaultProps = {
  id: '',
  cornerActivity: false,
  official: false,
  allowEdit: false,
};

export default Activity;
