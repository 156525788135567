import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import moment from 'moment';
import _ from 'lodash';

import App from './App';

import registerServiceWorker from './serviceWorker';
import reducer from './redux/reducers';
import rootSaga from './redux/actions';
import packageJson from '../package.json';

import 'react-awesome-button/dist/styles.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import './App.css';

moment.locale('vi', {
  months: _.range(12).map(i => `Tháng ${i + 1}`),
  monthsShort: _.range(12).map(i => `Tháng ${i + 1}`),
  monthsParseExact: true,
  weekdays: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
  weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[Hôm nay lúc] LT',
    nextDay: '[Ngày mai lúc] LT',
    nextWeek: 'dddd [tuần tới] LT',
    lastDay: '[Hôm qua lúc] LT',
    lastWeek: 'dddd [tuần trước] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: 'trong %s',
    past: '%s trước',
    s: 'vài giây',
    m: 'một phút',
    mm: '%d phút',
    h: '1 tiếng',
    hh: '%d tiếng',
    d: 'một ngày',
    dd: '%d ngày',
    M: 'một tháng',
    MM: '%d tháng',
    y: 'một năm',
    yy: '%d năm'
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4  // The week that contains Jan 4th is the first week of the year.
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //eslint-disable-line
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

ReactDOM.render((
  <Provider store={store}>
    <>
      <App />
      <div
        style={{
          height: 50,
          color: '#f5f8fa',
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          padding: '1rem',
          textAlign: 'center',
          background: '#394b59',
          fontFamily: 'sans-serif, Arial, Verdana, \'Trebuchet MS\'',
          zIndex: 1000
        }}
      >
        <center>{`LIBRARY v${packageJson.version} - © 2019, XBOT Technology JSC. All Rights Reserved.`}</center>
      </div>
    </>
  </Provider>
), document.getElementById('root'));

registerServiceWorker();
