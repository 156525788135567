import React, {
  useState,
  useEffect,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import {
  Icon,
  Intent,
  Tag,
  Navbar,
  FormGroup,
  Alignment,
  Alert
} from '@blueprintjs/core';
import Parser from 'html-react-parser';
import { AllHtmlEntities } from 'html-entities';
import {
  IntegratedFiltering,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFixedColumns,
  Table,
} from '@devexpress/dx-react-grid-material-ui';

import {
  Waiting,
  Input,
  Dropdown,
  warning,
} from '../../components';
import Activity from './Activity';

import {
  handleGetOfficial,
  handleGetActivity,
  handleUpdateActivity,
  handleClearActivity
} from '../../redux/actions/edubot';


const htmlDecoder = new AllHtmlEntities();

function Official() {
  const dispatch = useDispatch();
  const {
    admin,
    mod,
    catalogs,
    activities,
    editor,
    handling,
  } = useSelector(({ catalogs: catalogsStore, edubot, user }) => ({
    admin: _.get(user, 'roles.admin', false),
    mod: _.get(user, 'roles.mod', false),
    catalogs: catalogsStore,
    activities: _.get(edubot, 'official.activities', {}),
    editor: _.get(edubot, 'official.editor', {}),
    handling: _.get(edubot, 'official.handling', false)
  }));

  const [filter, setFilter] = useState('');
  const [period, setPeriod] = useState('');
  const [from, setFrom] = useState('1');
  const [to, setTo] = useState('100');
  const [remove, setRemove] = useState();

  useEffect(() => {
    if (period) dispatch(handleGetOfficial(period));
  }, [period]);

  const columns = useMemo(() => [
    { name: 'id', title: ' ', width: 80 },
    { name: 'name', title: 'Tên hoạt động', wordWrapEnabled: true },
    {
      name: 'category',
      title: 'Giờ sinh hoạt',
      width: 150,
      wordWrapEnabled: true
    },
    {
      name: 'tags',
      title: 'Từ khoá',
      width: 350,
      wordWrapEnabled: true
    }
  ], []);
  const rows = useMemo(() => {
    const records = [];
    const filterName = (filters, content) => {
      if (filters.length === 0)
        return true;
      const lowerContent = _.toLower(htmlDecoder.decode(content));
      return _.every(filters, (element) => lowerContent.indexOf(element) !== -1);
    };
    const readName = (act) => {
      const { activity, corners } = act;
      if (corners)
        return (
          <ol>
            {_.map(corners, (item, index) => (
              <li key={index}>
                <strong>{`${item.corner}. `}</strong>
                {Parser(`${item.activity}`)}
              </li>
            ))}
          </ol>
        );
      return Parser(`${activity}`);
    };
    _.forEach(activities, (act, id) => {
      const { corners, activity } = act;
      const cornerActivity = act.corners !== undefined;
      let pass = true;
      if (pass && filter) {
        const words = _.words(_.toLower(filter));
        if (cornerActivity)
          pass = _.some(corners, corner => filterName(words, `${corner.corner}: ${corner.activity}`));
        else
          pass = filterName(words, activity);
      }
      if (pass)
        records.push({
          id,
          name: readName(act),
          cornerActivity,
          ...act
        });
    });
    return _.slice(_.orderBy(records, ['id'], ['asc']), from - 1, to);
  }, [activities, filter, from, to]);

  return (
    <div>
      {handling ? <Waiting /> : null}
      {_.keys(editor).length ? (
        <Activity
          id={editor.id}
          official
          closeHandler={() => dispatch(handleClearActivity())}
          cornerActivity={editor.corners !== undefined}
          allowEdit={mod || admin}
        />
      ) : null}
      {remove ? (
        <Alert
          cancelButtonText="Huỷ"
          confirmButtonText="Xoá"
          icon="trash"
          intent={Intent.DANGER}
          isOpen
          onCancel={() => setRemove()}
          onConfirm={() => dispatch(handleUpdateActivity(remove, undefined, () => setRemove()))}
        >
          Sau khi xoá thì sẽ không thể khôi phục được!!!
        </Alert>
      ) : null}
      <Navbar style={{ boxShadow: 'none' }}>
        <Navbar.Group align={Alignment.LEFT}>
          <FormGroup label="Giờ sinh hoạt" inline style={{ marginLeft: 15 }}>
            <Dropdown
              value={period}
              placeholder="Chọn giờ sinh hoạt"
              items={_.map(_.get(catalogs, 'periods.data', {}), (data, key) => ({
                value: key,
                text: data.label
              }))}
              onChange={setPeriod}
            />
          </FormGroup>
          <FormGroup label="Tìm kiếm" inline style={{ marginLeft: 15 }}>
            <Input
              value={filter}
              onChange={setFilter}
              placeholder="Tìm theo tên hoạt động"
            />
          </FormGroup>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <FormGroup label="Hiển thị" inline style={{ marginRight: 15 }}>
            <div style={{ display: 'flex' }}>
              <Input
                width={50}
                value={from}
                onChange={(value) => {
                  if (!_.toNumber(value)) warning('Giá trị phải là số nguyên dương.');
                  else setFrom(value);
                }}
              />
              <span style={{ marginTop: 5 }}>-</span>
              <Input
                width={50}
                value={to}
                onChange={(value) => {
                  if (!_.toNumber(value)) warning('Giá trị phải là số nguyên dương.');
                  else setTo(value);
                }}
              />
            </div>
          </FormGroup>
          {period ? <Tag minimal intent={Intent.PRIMARY}>{`${_.keys(activities).length} HĐ`}</Tag> : null}
        </Navbar.Group>
      </Navbar>
      {rows.length ? (
        <Grid
          style={{ width: '70%' }}
          rows={rows}
          columns={columns}
          getRowId={row => row.id}
        >
          <DataTypeProvider
            formatterComponent={({ value }) => {
              if (!value || !(mod || admin))
                return null;
              return (
                <div>
                  {admin ? (
                    <Icon
                      icon="small-minus"
                      iconSize={16}
                      style={{ cursor: 'pointer', padding: 2 }}
                      intent={Intent.WARNING}
                      onClick={() => setRemove(value)}
                    />
                  ) : null}
                  <Icon
                    icon="document-open"
                    iconSize={16}
                    style={{ cursor: 'pointer', padding: 2 }}
                    intent={Intent.PRIMARY}
                    onClick={() => dispatch(handleGetActivity(value))}
                  />
                </div>
              );
            }}
            for={['id']}
          />
          <DataTypeProvider
            formatterComponent={({ value }) => _.compact(_.map(value || [], key => (
              <Tag minimal key={key} intent={Intent.WARNING}>
                {_.get(catalogs, `periods.data.${key}.label`, value)}
              </Tag>
            )))}
            for={['category']}
          />
          <DataTypeProvider
            formatterComponent={({ value }) => _.compact(_.map(value || [], key => (
              <Tag minimal key={key} style={{ marginRight: 5 }}>
                {_.get(catalogs, `tags.data.${key}.label`, value)}
              </Tag>
            )))}
            for={['tags']}
          />
          <IntegratedFiltering />
          <Table
            columnExtensions={_.map(columns, col => ({ columnName: col.name, ...col }))}
            messages={{ noData: 'Chưa có dữ liệu.' }}
          />
          <TableHeaderRow />
          <TableFixedColumns
            leftColumns={['id', 'name']}
          />
        </Grid>
      ) : null}
    </div>
  );
}

export default Official;
