import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@blueprintjs/core';

import { WordEditor } from '../../components';


function Editor({
  onChange,
  onShortChange,
  title,
  value,
  short,
  allowEdit,
}) {
  return (
    <div style={{ display: 'flex' }}>
      <Card style={{ width: '95%' }}>
        <h4>{title}</h4>
        <WordEditor
          id="value"
          data={value}
          onChange={onChange}
          readonly={!allowEdit}
        />
      </Card>
      <Card style={{ width: '95%' }}>
        <h4>Tóm tắt</h4>
        <WordEditor
          id="short"
          data={short || value}
          onChange={onShortChange}
          readonly={!allowEdit}
        />
      </Card>
    </div>
  );
}

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onShortChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  short: PropTypes.string,
  allowEdit: PropTypes.bool,
};

Editor.defaultProps = {
  value: '',
  short: '',
  allowEdit: false,
};

export default Editor;
