import _ from 'lodash';

import { SETTINGS, SIGN_OUT } from '../actions/constants';


const initialState = {
  dark: false
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SETTINGS.update:
      const newState = _.cloneDeep(state);
      _.forEach(action.settings, (val, key) => {
        newState[key] = val;
      });
      return {
        ...newState
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
