/* eslint-disable function-paren-newline */

import {
  put,
  call,
  all,
  select,
  takeLatest
} from 'redux-saga/effects';
import _ from 'lodash';
import uuid from 'uuid/v4';
import axios from 'axios';

import { warning, success } from '../../components';

import { app } from '../../libs/firebase';
import { EDUBOT } from './constants';
import { BACKEND_URL } from '../../libs/config';
import { exception } from '../../libs/utils';


export function* updatePending(activities = {}) {
  yield put({
    type: EDUBOT.pending.update,
    activities
  });
}

export function* updateOfficial(activities = {}) {
  yield put({
    type: EDUBOT.official.fetch,
    activities
  });
}

export function* updateActivity(activity = {}) {
  yield put({
    type: EDUBOT.official.update,
    activity
  });
}

export function* approvePendingActivity({ id, approved, onSuccess }) {
  const { email } = _.get(yield select(), 'user', {});
  const successHandler = () => {
    if (success)
      success('Cập nhật thành công');
    onSuccess();
  };

  const contents = {
    approved: approved ? email : undefined,
    time: new Date().getTime()
  };

  try {
    const token = yield call(() => new Promise((resolve, reject) => {
      app.auth().currentUser.getIdToken(true).then(result => {
        resolve(result);
      }).catch(err => reject(err));
    }));
    yield call(() => new Promise((resolve, reject) => {
      axios.post(`${BACKEND_URL}/userRequest`, {
        token,
        project: 'library',
        type: 'database',
        data: JSON.stringify({
          isSupport: false,
          command: 'update',
          collections: ['edulib'],
          documents: [id],
          contents
        })
      }).then(result => resolve(_.get(result, 'data', []))).catch(err => reject(err));
    }));
    successHandler();
    yield put({
      type: EDUBOT.pending.approve,
      id,
      ...contents
    });
  } catch ({ message }) {
    warning(message);
    yield* updatePending();
  }
}

export function* updatePendingActivity({ id, activity, onSuccess }) {
  const { email } = _.get(yield select(), 'user', {});
  const isNew = !id;
  const actID = id || uuid();
  const successHandler = () => {
    if (success)
      success('Cập nhật thành công');
    onSuccess();
  };
  try {
    const token = yield call(() => new Promise((resolve, reject) => {
      app.auth().currentUser.getIdToken(true).then(result => {
        resolve(result);
      }).catch(err => reject(err));
    }));
    if (activity) {
      const contents = {
        ...activity,
        modified: email,
        time: new Date().getTime()
      };
      if (isNew)
        contents.created = email;
      yield call(() => new Promise((resolve, reject) => {
        axios.post(`${BACKEND_URL}/userRequest`, {
          token,
          project: 'library',
          type: 'database',
          data: JSON.stringify({
            isSupport: false,
            command: 'update',
            collections: ['edulib'],
            documents: [actID],
            contents
          })
        }).then(result => resolve(_.get(result, 'data', []))).catch(err => reject(err));
      }));
      successHandler();
      yield* updatePending({ [actID]: contents });
    } else {
      yield call(() => new Promise((resolve, reject) => {
        axios.post(`${BACKEND_URL}/userRequest`, {
          token,
          project: 'library',
          type: 'database',
          data: JSON.stringify({
            isSupport: false,
            command: 'delete',
            collections: ['edulib'],
            documents: [id]
          })
        }).then(result => resolve(_.get(result, 'data', []))).catch(err => reject(err));
      }));
      successHandler();
      yield put({
        type: EDUBOT.pending.remove,
        id
      });
    }
  } catch ({ message }) {
    warning(message);
    yield* updatePending();
  }
}

export function* updateOfficialActivity({ id, activity, onSuccess }) {
  const successHandler = () => {
    if (success)
      success('Cập nhật thành công');
    onSuccess();
  };

  const { email } = _.get(yield select(), 'user', {});
  const { category, tags } = _.get(yield select(), `edubot.official.activities.${id}`, {});
  const contents = _.cloneDeep(_.get(yield select(), 'edubot.official.editor', {}));
  contents.approved = email;
  contents.time = new Date().getTime();
  try {
    const token = yield call(() => new Promise((resolve, reject) => {
      app.auth().currentUser.getIdToken(true).then(result => {
        resolve(result);
      }).catch(err => reject(err));
    }));
    const { category: newCategory, tags: newTags } = activity;
    if (!_.isEqual(category || [], newCategory || []) || !_.isEqual(tags || [], newTags || [])) {
      if (!activity) {
        yield call(() => new Promise((resolve, reject) => {
          axios.post(`${BACKEND_URL}/userRequest`, {
            token,
            project: 'library',
            type: 'database',
            data: JSON.stringify({
              isSupport: false,
              command: 'delete',
              collections: ['edusearch'],
              documents: [id]
            })
          }).then(() => resolve(true)).catch(err => reject(err));
        }));
        yield put({
          type: EDUBOT.official.search,
          id
        });
      } else {
        yield call(() => new Promise((resolve, reject) => {
          axios.post(`${BACKEND_URL}/userRequest`, {
            token,
            project: 'library',
            type: 'database',
            data: JSON.stringify({
              isSupport: false,
              command: 'update',
              collections: ['edusearch'],
              documents: [id],
              contents: {
                category: newCategory || [],
                tags: newTags || []
              }
            })
          }).then(() => resolve(true)).catch(err => reject(err));
        }));
        yield put({
          type: EDUBOT.official.search,
          id,
          category,
          tags
        });
      }
    }
    if (activity) {
      _.forEach([
        'content',
        'detail',
        'prepare',
        'requirement',
        'before',
        'corners',
        'after',
        'short',
      ], field => {
        if (activity[field])
          contents[field] = activity[field];
      });
      yield call(() => new Promise((resolve, reject) => {
        axios.post(`${BACKEND_URL}/userRequest`, {
          token,
          project: 'library',
          type: 'database',
          data: JSON.stringify({
            isSupport: false,
            command: 'update',
            collections: ['eduofficial'],
            documents: [id],
            contents
          })
        }).then(() => resolve(true)).catch(err => reject(err));
      }));
    }
    successHandler();
    yield* updateActivity(contents);
  } catch ({ message }) {
    warning(message);
    yield* updateActivity();
  }
}

export function* getOfficial({ period }) {
  try {
    const token = yield call(() => new Promise((resolve, reject) => {
      app.auth().currentUser.getIdToken(true).then(result => {
        resolve(result);
      }).catch(err => reject(err));
    }));
    const acts = yield call(() => new Promise((resolve, reject) => {
      axios.post(`${BACKEND_URL}/userRequest`, {
        token,
        project: 'library',
        type: 'database',
        data: JSON.stringify({
          isSupport: false,
          command: 'get',
          collections: ['edusearch'],
          documents: [],
          conditions: {
            category: period
          }
        })
      }).then(result => resolve(_.get(result, 'data', []))).catch(err => reject(err));
    }));
    const activities = {};
    _.forEach(acts, act => {
      const { _id, ...rest } = act;
      activities[_id] = rest; //eslint-disable-line
    });
    yield* updateOfficial(activities);
  } catch ({ message }) {
    warning(message);
    yield* updateOfficial();
  }
}

export function* getActivity({ id }) {
  try {
    const token = yield call(() => new Promise((resolve, reject) => {
      app.auth().currentUser.getIdToken(true).then(result => {
        resolve(result);
      }).catch(err => reject(err));
    }));
    const act = yield call(() => new Promise((resolve, reject) => {
      axios.post(`${BACKEND_URL}/userRequest`, {
        token,
        project: 'library',
        type: 'database',
        data: JSON.stringify({
          isSupport: false,
          command: 'get',
          collections: ['eduofficial'],
          documents: [id]
        })
      }).then(result => resolve(_.get(result, 'data.[0]', {}))).catch(err => reject(err));
    }));
    _.unset(act, '_id');
    act.id = id;
    yield* updateActivity(act);
  } catch ({ message }) {
    warning(message);
    yield* updateActivity();
  }
}

export function* getPendingActivities() {
  const { roles, email } = _.get(yield select(), 'user', {});
  const created = _.get(roles, 'mod', false) ? '' : email;
  try {
    const token = yield call(() => new Promise((resolve, reject) => {
      app.auth().currentUser.getIdToken(true).then(result => {
        resolve(result);
      }).catch(err => reject(err));
    }));
    exception(token);
    const acts = yield call(() => new Promise((resolve, reject) => {
      axios.post(`${BACKEND_URL}/userRequest`, {
        token,
        project: 'library',
        type: 'database',
        data: JSON.stringify({
          isSupport: false,
          command: 'get',
          collections: ['edulib'],
          documents: [],
          conditions: created ? {
            created
          } : {}
        })
      }).then(result => resolve(_.get(result, 'data', []))).catch(err => reject(err));
    }));
    const activities = {};
    _.forEach(acts, act => {
      const { _id, ...rest } = act;
      activities[_id] = rest; //eslint-disable-line
    });
    yield* updatePending(activities);
  } catch ({ message }) {
    warning(message);
    yield* updatePending();
  }
}

export const handleUpdatePendingActivity = (id, activity, onSuccess) => ({
  type: EDUBOT.handlers.pending.update,
  id,
  activity,
  onSuccess
});

export const handleApprovePendingActivity = (id, approved, onSuccess) => ({
  type: EDUBOT.handlers.pending.approve,
  id,
  approved,
  onSuccess
});

export const handleGetPending = () => ({
  type: EDUBOT.handlers.pending.activities
});

export const handleGetSearch = (period) => ({
  type: EDUBOT.handlers.search.activities,
  period
});

export const handleGetOfficial = (period) => ({
  type: EDUBOT.handlers.official.activities,
  period
});

export const handleGetActivity = (id) => ({
  type: EDUBOT.handlers.official.activity,
  id
});

export const handleUpdateActivity = (id, activity, onSuccess) => ({
  type: EDUBOT.handlers.official.update,
  id,
  activity,
  onSuccess
});

export const handleClearActivity = () => ({
  type: EDUBOT.official.clear
});

export default function* saga() {
  yield all([
    yield takeLatest(EDUBOT.handlers.pending.activities, getPendingActivities),
    yield takeLatest(EDUBOT.handlers.pending.update, updatePendingActivity),
    yield takeLatest(EDUBOT.handlers.pending.approve, approvePendingActivity),
    yield takeLatest(EDUBOT.handlers.official.activities, getOfficial),
    yield takeLatest(EDUBOT.handlers.official.activity, getActivity),
    yield takeLatest(EDUBOT.handlers.official.update, updateOfficialActivity)
  ]);
}
