export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    update: 'USER_UPDATE_HANDLER'
  },
  update: 'USER_UPDATE',
  roles: 'USER_ROLES'
};

export const CATALOGS = {
  tags: 'CATALOGS_TAGS',
  periods: 'CATALOGS_PERIODS'
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const EDUBOT = {
  handlers: {
    pending: {
      activities: 'EDUBOT_HANDLER_PENDING_ACTIVITIES',
      update: 'EDUBOT_HANDLER_PENDING_UPDATE',
      approve: 'EDUBOT_HANDLER_PENDING_APPROVE'
    },
    search: {
      activities: 'EDUBOT_HANDLER_SEARCH_ACTIVITIES',
      update: 'EDUBOT_HANDLER_SEARCH_UPDATE',
    },
    official: {
      activities: 'EDUBOT_HANDLER_OFFICIAL_ACTIVITIES',
      activity: 'EDUBOT_HANDLER_OFFICIAL_ACTIVITY',
      update: 'EDUBOT_HANDLER_OFFICIAL_UPDATE'
    }
  },
  pending: {
    update: 'EDUBOT_PENDING_UPDATE',
    remove: 'EDUBOT_PENDING_REMOVE',
    approve: 'EDUBOT_PENDING_APPROVE'
  },
  search: 'EDUBOT_SEARCH_UPDATE',
  official: {
    fetch: 'EDUBOT_OFFICIAL_FETCH',
    update: 'EDUBOT_OFFICIAL_UPDATE',
    search: 'EDUBOT_OFFICIAL_SEARCH',
    clear: 'EDUBOT_OFFICIAL_CLEAR'
  }
};

export const SIGN_OUT = 'SIGN_OUT';
