import { Position, Toaster, Intent } from '@blueprintjs/core';

const Notification = Toaster.create({
  position: Position.TOP_RIGHT,
  canEscapeKeyClear: true
});

const warning = (message) => Notification.show({
  message,
  timeout: 3000,
  intent: Intent.WARNING,
  icon: 'warning-sign'
});

const success = (message) => Notification.show({
  message,
  timeout: 3000,
  intent: Intent.SUCCESS,
  icon: 'tick'
});

export { warning, success };

