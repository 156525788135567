import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Tree,
  Checkbox,
  Button,
  Classes,
  Intent
} from '@blueprintjs/core';
import _ from 'lodash';
import { connect } from 'react-redux';


class Tags extends Component {
  constructor(props) {
    super(props);

    const { selected } = this.props;
    this.state = {
      nodes: this.convert2Tree(),
      selected,
      expanded: {}
    };
  }

  convert2Tree = () => {
    const { tags } = this.props;
    const flat = _.map(tags, (data, key) => ({ key, ...data }));
    const root = {};
    // hardcode (6lv of depth)
    _.forEach(_.filter(flat, t => t.groups.length === 1), tag => {
      const { key, ...data } = tag;
      root[key] = {
        ...data,
        childTags: {}
      };
    });
    _.forEach(_.filter(flat, t => t.groups.length === 2), tag => {
      const { key, groups, ...data } = tag;
      root[groups[1]].childTags[key] = {
        groups,
        ...data,
        childTags: {}
      };
    });
    _.forEach(_.filter(flat, t => t.groups.length === 3), tag => {
      const { key, groups, ...data } = tag;
      root[groups[1]].childTags[groups[2]].childTags[key] = {
        groups,
        ...data,
        childTags: {}
      };
    });
    _.forEach(_.filter(flat, t => t.groups.length === 4), tag => {
      const { key, groups, ...data } = tag;
      root[groups[1]].childTags[groups[2]].childTags[groups[3]].childTags[key] = {
        groups,
        ...data,
        childTags: {}
      };
    });
    _.forEach(_.filter(flat, t => t.groups.length === 5), tag => {
      const { key, groups, ...data } = tag;
      root[groups[1]].childTags[groups[2]].childTags[groups[3]].childTags[groups[4]].childTags[key] = {
        groups,
        ...data,
        childTags: {}
      };
    });
    _.forEach(_.filter(flat, t => t.groups.length === 6), tag => {
      const { key, groups, ...data } = tag;
      root[groups[1]].childTags[groups[2]].childTags[groups[3]].childTags[groups[4]].childTags[groups[5]].childTags[key] = {
        groups,
        ...data,
        childTags: {}
      };
    });
    return root;
  }

  convert2Nodes = () => {
    const { tags } = this.props;
    const { nodes } = this.state;
    const checkHandler = (id) => {
      const { selected } = this.state;
      const checked = _.indexOf(selected, id) !== -1; 
      const groups = _.get(tags, `${id}.groups`, []);
      if (!checked) {
        const newSelected = _.cloneDeep(selected);
        newSelected.push(id);
        _.forEach(groups, parent => {
          if (_.indexOf(newSelected, parent) === -1)
            newSelected.push(parent);
        });
        this.setState({ selected: _.uniq(newSelected) });
      } else {
        const newSelected = [];
        _.forEach(selected, key => {
          if (key !== id && _.indexOf(_.get(tags, `${key}.groups`, []), id) === -1)
            newSelected.push(key);
        });
        this.setState({ selected: _.uniq(newSelected) });
      }
    };

    const recursive = (node, id) => {
      const { selected, expanded } = this.state;
      const { childTags, label } = node;
      const checked = _.indexOf(selected, id) !== -1;
      return {
        id,
        label,
        isExpanded: _.get(expanded, id, false),
        hasCaret: _.keys(childTags).length > 0,
        icon: 'folder-close',
        childNodes: _.map(childTags, (child, key) => recursive(child, key)),
        secondaryLabel: <Checkbox checked={checked} onChange={() => checkHandler(id)} />
      };
    };

    return _.map(nodes, (node, id) => recursive(node, id));
  }

  render() {
    const { selected, expanded } = this.state;
    const { closeHandler, updateHandler, dark } = this.props;
    return (
      <Dialog
        title="Chọn từ khoá"
        onClose={closeHandler}
        isOpen
        className={dark ? 'bp3-dark' : ''}
        canOutsideClickClose={false}
      >
        <div className={Classes.DALOG_BODY} style={{ padding: 10 }}>
          <Tree
            contents={this.convert2Nodes()}
            onNodeExpand={node => {
              this.setState({ expanded: _.set(expanded, node.id, true) });
            }}
            onNodeCollapse={node => {
              this.setState({ expanded: _.set(expanded, node.id, false) });
            }}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              rightIcon="arrow-right"
              text="Tiếp tục"
              intent={Intent.PRIMARY}
              onClick={() => {
                updateHandler(_.filter(selected, t => t !== 'tags'));
                closeHandler();
              }}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

Tags.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  dark: PropTypes.bool.isRequired
};

Tags.defaultProps = {
  selected: []
};

const mapStateToProps = ({ catalogs, settings }) => ({
  tags: _.get(catalogs, 'tags.data', {}),
  dark: _.get(settings, 'dark', false)
});

export default connect(mapStateToProps)(Tags);
