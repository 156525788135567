import { CATALOGS, SIGN_OUT } from '../actions/constants';


const initialState = {
  tags: {
    handling: true
  },
  periods: {
    handling: true
  }
};

export default function catalogs(state = initialState, action) {
  const { data } = action;

  switch (action.type) {
    case CATALOGS.tags:
      return {
        ...state,
        tags: {
          data,
          handling: false
        }
      };
    case CATALOGS.periods:
      return {
        ...state,
        periods: {
          data,
          handling: false
        }
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
