import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Alignment,
  Button,
  Intent,
  Tag,
  Icon,
  FormGroup,
  Alert
} from '@blueprintjs/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Parser from 'html-react-parser';
import { AllHtmlEntities } from 'html-entities';
import {
  IntegratedFiltering,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableFixedColumns,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import axios from 'axios';
import { saveAs } from 'file-saver';

import {
  Waiting,
  Input,
  MultiSelector
} from '../../components';
import Activity from './Activity';

import { handleGetPending, handleUpdatePendingActivity } from '../../redux/actions/edubot';
import { app } from '../../libs/firebase';
import { exception } from '../../libs/utils';
import { BACKEND_URL } from '../../libs/config';

const htmlDecoder = new AllHtmlEntities();


class Pending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      periods: []
    };
  }

  componentDidMount() {
    const { handleGetPending: handler } = this.props;
    handler();
  }

  filterName = (filters, content) => {
    if (filters.length === 0)
      return true;
    const lowerContent = _.toLower(htmlDecoder.decode(content));
    return _.every(filters, (element) => lowerContent.indexOf(element) !== -1);
  }

  generateActivities = (allRows) => {
    const rows = _.filter(allRows, row => row.approved);
    exception([`Total activities: ${rows.length}`, `Corners activities: ${_.filter(rows, row => row.cornerActivity).length}`]);
    app.auth().currentUser.getIdToken(true).then(token => {
      let count = 0;
      const total = rows.length;
      const activities = [];
      _.forEach(rows, row => {
        const {
          id,
          category,
          tags,
          before,
          after,
          corners,
          created,
          time,
          approved,
          content,
          detail,
          prepare,
          requirement
        } = row;
        axios.post(`${BACKEND_URL}/userRequest`, {
          token,
          project: 'library',
          type: 'database',
          data: JSON.stringify({
            isSupport: false,
            command: 'get',
            collections: ['edusearch'],
            documents: [id]
          })
        }).then(result => {
          count += 1;
          if (_.get(result, 'data', []).length)
            exception(`${count}/${total}`, `Dupplicate ${id}`);
          else {
            const searchContents = { tags, category };
            const official = {
              approved,
              time,
              created
            };
            if (corners !== undefined) {
              searchContents.corner = true;
              searchContents.corners = [];
              official.before = before;
              official.after = after;
              official.corners = [];
              _.forEach(corners, c => {
                const {
                  corner,
                  content: cContent,
                  requirement: cRequirement,
                  detail: cDetail,
                  prepare: cPrepare
                } = c;
                searchContents.corners.push({
                  activity: cContent,
                  corner
                });
                official.corners.push({
                  detail: cDetail,
                  content: cContent,
                  prepare: cPrepare,
                  requirement: cRequirement,
                  corner
                });
              });
            } else {
              searchContents.activity = content;
              official.content = content;
              official.detail = detail;
              official.prepare = prepare;
              official.requirement = requirement;
            }
            exception(`${count}/${total}`, { searchContents, official });
            activities.push({
              id,
              searchContents,
              official
            });
            if (count === total) {
              saveAs(new Blob([JSON.stringify(activities)]), 'activities.json');
            }
          }
        }).catch(() => {
          count += 1;
          exception(`${count}/${total}`, `Failed ${id}`);
        });
      });
    }).catch(err => {
      exception(err);
    });
  }

  updateHandler = (rows) => {
    // this.generateActivities(rows);
    // return;
    // const activities = require('./activities.json');
    const activities = rows;
    const updateOfficial = false;
    exception([`Total activities: ${activities.length}`, `Corners activities: ${_.filter(activities, row => row.searchContents.corner).length}`]);
    app.auth().currentUser.getIdToken(true).then(token => {
      let count = 0;
      const total = _.keys(activities).length;
      _.forEach(activities, row => {
        const {
          id,
          searchContents,
          official
        } = row;
        axios.post(`${BACKEND_URL}/userRequest`, {
          token,
          project: 'library',
          type: 'database',
          data: JSON.stringify({
            isSupport: false,
            command: 'update',
            collections: updateOfficial ? ['eduofficial'] : ['edusearch'],
            documents: [id],
            contents: updateOfficial ? official : searchContents
          })
        }).then(() => {
          count += 1;
          exception(`${count}/${total}`);
        }).catch(() => {
          count += 1;
          exception(`${count}/${total}`, `Official Failed ${id}`);
        });
      });
    }).catch(err => {
      exception(err);
    });
  }

  readName = (activity) => {
    const { content, corners } = activity;
    if (corners)
      return (
        <ol>
          {_.map(corners, (item, index) => (
            <li key={index}>
              <strong>{`${item.corner}. `}</strong>
              {Parser(`${item.content}`)}
            </li>
          ))}
        </ol>
      );
    return Parser(`${content}`);
  }

  renderMenu = (rows) => {
    const { filter, periods } = this.state;
    const { catalogs, user } = this.props;
    return (
      <Navbar style={{ boxShadow: 'none' }}>
        <Navbar.Group align={Alignment.LEFT}>
          <FormGroup label="Tìm kiếm" inline>
            <Input
              value={filter}
              onChange={(value) => this.setState({ filter: value })}
              placeholder="HĐ/Người soạn/duyệt"
            />
          </FormGroup>
          <FormGroup label="Giờ sinh hoạt" inline style={{ marginLeft: 15 }}>
            <MultiSelector
              placeholder="Chọn tất cả"
              selected={periods}
              items={_.map(_.get(catalogs, 'periods.data', {}), (data, key) => ({
                value: key,
                text: data.label
              }))}
              onChange={selected => this.setState({ periods: selected })}
            />
          </FormGroup>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {_.get(user, 'roles.admin') ? (
            <Button
              style={{ marginRight: 10 }}
              intent={Intent.DANGER}
              text="Cập nhật thư viện"
              onClick={() => this.updateHandler(rows)}
            />
          ) : null}
          <Button
            style={{ marginRight: 10 }}
            icon="add"
            text="Hoạt động"
            intent={Intent.PRIMARY}
            onClick={() => this.setState({
              activity: {
                id: '',
                cornerActivity: false
              }
            })}
          />
          <Button
            icon="add"
            text="Hoạt động góc"
            intent={Intent.SUCCESS}
            onClick={() => this.setState({
              activity: {
                id: '',
                cornerActivity: true
              }
            })}
          />
        </Navbar.Group>
      </Navbar>
    );
  }

  renderActions = ({ value, row }) => {
    if (!value)
      return null;
    return (
      <div>
        <Icon
          icon="small-minus"
          iconSize={16}
          style={{ cursor: 'pointer', padding: 2 }}
          intent={Intent.WARNING}
          onClick={() => this.setState({ remove: value })}
        />
        <Icon
          icon="edit"
          iconSize={16}
          style={{ cursor: 'pointer', padding: 2 }}
          intent={Intent.PRIMARY}
          onClick={() => this.setState({ activity: row })}
        />
      </div>
    );
  }

  renderContent = (rows) => {
    const { catalogs } = this.props;
    const columns = [
      { name: 'id', title: ' ', width: 80 },
      { name: 'name', title: 'Tên hoạt động', wordWrapEnabled: true },
      {
        name: 'category',
        title: 'Giờ sinh hoạt',
        width: 150,
        wordWrapEnabled: true
      },
      {
        name: 'tags',
        title: 'Từ khoá',
        width: 150,
        wordWrapEnabled: true
      },
      {
        name: 'created',
        title: 'Người soạn',
        width: 150,
        wordWrapEnabled: true
      },
      {
        name: 'approved',
        title: 'Người duyệt',
        width: 150,
        wordWrapEnabled: true
      }
    ];
    return (
      <Grid
        style={{ width: '70%' }}
        rows={rows}
        columns={columns}
        getRowId={row => row.id}
      >
        <DataTypeProvider
          formatterComponent={this.renderActions}
          for={['id']}
        />
        <DataTypeProvider
          formatterComponent={({ value }) => _.compact(_.map(value || [], key => (
            <Tag minimal key={key}>
              {_.get(catalogs, `periods.data.${key}.label`, value)}
            </Tag>
          )))}
          for={['category']}
        />
        <DataTypeProvider
          formatterComponent={({ value }) => _.compact(_.map(value || [], key => (
            <Tag minimal key={key}>
              {_.get(catalogs, `tags.data.${key}.label`, value)}
            </Tag>
          )))}
          for={['tags']}
        />
        <DataTypeProvider
          formatterComponent={({ value }) => (
            <Tag
              round
              minimal
              intent={Intent.SUCCESS}
            >
              {value}
            </Tag>
          )}
          for={['created']}
        />
        <DataTypeProvider
          formatterComponent={({ value }) => value ? (
            <Tag
              round
              minimal
              intent={Intent.WARNING}
            >
              {value}
            </Tag>
          ) : null}
          for={['approved']}
        />
        <IntegratedFiltering />
        <Table
          columnExtensions={_.map(columns, col => ({ columnName: col.name, ...col }))}
          messages={{ noData: 'Chưa có dữ liệu.' }}
        />
        <TableHeaderRow />
        <TableFixedColumns
          leftColumns={['id', 'name']}
        />
      </Grid>
    );
  }

  render() {
    const {
      handling,
      handleUpdatePendingActivity: removeHandler,
      activities,
      user
    } = this.props;
    const {
      activity,
      remove,
      filter,
      periods
    } = this.state;
    const rows = [];
    _.forEach(activities, (act, id) => {
      const {
        corners,
        category,
        content,
        created,
        approved
      } = act;
      const cornerActivity = act.corners !== undefined;
      let pass = true;
      if (periods.length)
        pass = _.every(category, period => _.indexOf(periods, period) !== -1);
      if (pass && filter) {
        const words = _.words(_.toLower(filter));
        if (cornerActivity)
          pass = _.some(corners, corner => this.filterName(words, `${corner.corner}: ${corner.content}`));
        else
          pass = this.filterName(words, content);
        if (!pass)
          pass = this.filterName(words, `${created} ${approved}`);
      }
      if (pass)
        rows.push({
          id,
          name: this.readName(act),
          cornerActivity,
          ...act
        });
    });
    return (
      <div>
        {handling ? <Waiting /> : null}
        {activity ? (
          <Activity
            closeHandler={() => this.setState({ activity: undefined })}
            allowEdit={_.get(user, 'roles.admin') || _.get(user, 'roles.mod')}
            {...activity}
          />
        ) : null}
        {remove ? (
          <Alert
            cancelButtonText="Huỷ"
            confirmButtonText="Xoá"
            icon="trash"
            intent={Intent.DANGER}
            isOpen
            onCancel={() => this.setState({ remove: undefined })}
            onConfirm={() => removeHandler(remove, undefined, () => this.setState({ remove: undefined }))}
          >
            Sau khi xoá thì sẽ không thể khôi phục được!!!
          </Alert>
        ) : null}
        {this.renderMenu(rows)}
        {this.renderContent(rows)}
      </div>
    );
  }
}

Pending.propTypes = {
  handleGetPending: PropTypes.func.isRequired,
  handleUpdatePendingActivity: PropTypes.func.isRequired,
  catalogs: PropTypes.shape({
    tags: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired
  }).isRequired,
  user: PropTypes.shape({
    photoURL: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.object
  }).isRequired,
  activities: PropTypes.object.isRequired,
  handling: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  user,
  catalogs,
  edubot
}) => ({
  user,
  catalogs,
  activities: _.get(edubot, 'pending.activities', {}),
  handling: _.get(edubot, 'pending.handling', false)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  handleGetPending,
  handleUpdatePendingActivity
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Pending);
