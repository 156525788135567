import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Alignment,
  Position,
  Button,
  Tag,
  Popover,
  Menu,
  Switch,
  Alert,
  Intent,
  Tabs,
  Tab
} from '@blueprintjs/core';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Edubot from './Edubot';

import { app } from '../libs/firebase';
import { RESOURCES } from '../libs/config';
import { handleUserSignOut } from '../redux/actions/user';
import { handleUpdateSettings } from '../redux/actions/settings';


const logo = `${RESOURCES}/logo.png`;

class Workspace extends Component {
  signOutHandler = () => {
    app.auth().signOut().then(() => {
      const { handleUserSignOut: handler } = this.props;
      handler();
    });
  }

  renderMenu = () => {
    const { user, settings } = this.props;
    const { 
      photoURL,
       email,
        displayName,
        roles 
      } = user;
    if (!_.get(roles, 'support'))
      return (
        <Alert
          isOpen
          confirmButtonText="Đăng xuất"
          icon="warning-sign"
          intent={Intent.DANGER}
          onConfirm={this.signOutHandler}
        >
          <p>Tài khoản chưa được cấp quyền. Xin vui lòng liên hệ Admin.</p>
        </Alert>
      );
    const darkMode = _.get(settings, 'dark', false);
    return (
      <Navbar fixedToTop>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Avatar src={logo} size={30} />
            {_.get(roles, 'mod')? <Tag minimal intent={Intent.DANGER}>MOD</Tag> : null}
            {_.get(roles, 'admin')? <Tag minimal intent={Intent.WARNING}>ADMIN</Tag> : null}
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <div>
            <Avatar
              round
              size={25}
              name={displayName}
              src={photoURL}
            />
            <Tag round minimal style={{ marginLeft: 5 }}>{email}</Tag>
          </div>
          <Popover
            content={(
              <Menu>
                <Switch
                  style={{ marginLeft: 30 }}
                  alignIndicator={Alignment.RIGHT}
                  checked={darkMode}
                  label="Dark Theme"
                  onChange={() => {
                    document.body.style.backgroundColor = darkMode ? 'white' : '#30404d';
                    const { handleUpdateSettings: handler } = this.props;
                    handler({ dark: !darkMode });
                  }}
                />
                <Menu.Divider />
                <Menu.Item
                  icon="log-out"
                  text="Đăng xuất"
                  onClick={this.signOutHandler}
                />
              </Menu>
            )}
            position={Position.LEFT_TOP}
          >
            <Button icon="cog" minimal />
          </Popover>
        </Navbar.Group>
      </Navbar>
    );
  }

  renderContent = () => {
    return (
      <div style={{ marginTop: 60, marginLeft: 10, marginRight: 10 }}>
        <Tabs
          animate
          id="content"
          renderActiveTabPanelOnly
        >
          <Tab
            id="edubot"
            title="EDUBOT"
            panel={<Edubot />}
          />
        </Tabs>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderMenu()}
        {this.renderContent()}
      </div>
    );
  }
}

Workspace.propTypes = {
  handleUpdateSettings: PropTypes.func.isRequired,
  handleUserSignOut: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    dark: PropTypes.bool
  }).isRequired,
  user: PropTypes.shape({
    photoURL: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.object
  }).isRequired
};

const mapStateToProps = ({ user, settings }) => ({
  user,
  settings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  handleUserSignOut,
  handleUpdateSettings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
