import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextArea,
  InputGroup
} from '@blueprintjs/core';
import _ from 'lodash';

import { warning } from '../Notification';


export default class Input extends Component {
  constructor(props) {
    super(props);

    const { value } = this.props;
    this.state = {
      value
    };
  }

  onChange = _.debounce(() => {
    const { value } = this.state;
    const { onChange } = this.props;
    onChange(value);
  }, 200);

  render() {
    const {
      placeholder,
      disabled,
      type,
      leftIcon,
      width,
      fill
    } = this.props;
    const { value } = this.state;
    const handleValueChanged = (newValue) => {
      if (type === 'number') {
        if (_.isNaN(_.toNumber(newValue))) {
          warning('Chỉ được nhập số.');
          return;
        }
      }
      this.setState({ value: newValue }, () => {
        this.onChange();
      });
    };

    switch (type) {
      case 'memo':
        return (
          <TextArea
            growVertically
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(event) => handleValueChanged(event.target.value)}
          />
        );
      default:
        return (
          <InputGroup
            style={width ? { width } : {}}
            fill={fill}
            leftIcon={leftIcon}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={(event) => handleValueChanged(event.target.value)}
          />
        );
    }
  }
}

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.string,
  width: PropTypes.number,
  fill: PropTypes.bool
};

Input.defaultProps = {
  onChange: () => { },
  value: '',
  placeholder: '',
  type: 'text',
  disabled: false,
  leftIcon: undefined,
  width: 0,
  fill: false
};
