import {
  put,
  call,
} from 'redux-saga/effects';
import axios from 'axios';

import { RESOURCES } from '../../libs/config';
import { CATALOGS } from './constants';
import { warning } from '../../components/Notification';


function* updateTags(data = {}) {
  yield put({
    type: CATALOGS.tags,
    data
  });
}

function* updatePeriods(data = {}) {
  yield put({
    type: CATALOGS.periods,
    data
  });
}

export function* queryTags() {
  try {
    const tags = yield call(() => new Promise((resolve, reject) => {
      axios.get(`${RESOURCES}/edubot/tags.json`).then(response => {
        const { status, data } = response;
        if (status === 200 && data)
          resolve(data);
        else
          reject(new Error(status));
      }).catch(err => reject(err));
    }));
    yield* updateTags(tags);
  } catch ({ message }) {
    yield* updateTags();
    warning(message);
  }
}

export function* queryPeriods() {
  try {
    const periods = yield call(() => new Promise((resolve, reject) => {
      axios.get(`${RESOURCES}/edubot/periods.json`).then(response => {
        const { status, data } = response;
        if (status === 200 && data)
          resolve(data);
        else
          reject(new Error(status));
      }).catch(err => reject(err));
    }));
    yield* updatePeriods(periods);
  } catch ({ message }) {
    yield* updatePeriods();
    warning(message);
  }
}
