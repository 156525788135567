import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import Parser from 'html-react-parser';


export default class WordEditor extends Component {
  render() {
    const {
      id,
      data,
      readonly,
      onChange,
      height
    } = this.props;
    if (readonly)
      return Parser(data);

    return (
      <Editor
        key={id}
        value={data}
        onEditorChange={value => onChange(value)}
        init={{
          plugins: 'colorpicker media contextmenu link paste save textcolor autolink fullscreen image insertdatetime lists preview searchreplace table',
          language: 'vi_VN',
          menubar: 'edit insert format table',
          toolbar: [
            'undo redo | cut copy paste | styleselect | bold italic | alignleft aligncenter alignright | link image',
            'bullist numlist | indent outdent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | fullscreen'
          ],
          statusbar: false,
          height,
        }}
      />
    );
  }
}

WordEditor.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.number
};

WordEditor.defaultProps = {
  readonly: false,
  onChange: () => { },
  height: 400
};
