import React, { Component } from 'react';
import { Modal } from '@material-ui/core';
import { Spinner } from '@blueprintjs/core';


export default class Waiting extends Component {
  render() {
    return (
      <Modal open>
        <center
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: -25,
            marginTop: -25
          }}
        >
          <Spinner co />
        </center>
      </Modal>
    );
  }
}
