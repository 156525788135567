import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AwesomeButtonSocial } from 'react-awesome-button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import Workspace from './screens/Workspace';
import { warning } from './components/index';

import { handleUserSignedIn } from './redux/actions/user';
import { handleUpdateSettings } from './redux/actions/settings';
import { app, googleProvider } from './libs/firebase';


class App extends Component {
  componentDidMount() {
    const settings = localStorage.getItem('settings');
    if (settings) {
      const parsed = JSON.parse(settings);
      const { handleUpdateSettings: settingsHandler } = this.props;
      settingsHandler(parsed);
      if (parsed.dark)
        document.body.style.backgroundColor = '#30404d';
    }
    this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
      const { handleUserSignedIn: handler } = this.props;
      handler(user);
    });
  }

  componentWillUnmount() {
    this.removeAuthListener();
  }

  authWithGoogle = () => {
    app.auth().signInWithPopup(googleProvider).then((user, error) => {
      if (error) {
        warning('Xin vui lòng kiểm tra lại kết nối internet hoặc liên hệ công ty để được hỗ trợ.');
      }
    });
  }

  workspaceReady = () => {
    const { catalogs } = this.props;
    return !(_.get(catalogs, 'tags.handling', false) && _.get(catalogs, 'periods.handling', false));
  }

  renderLoginScreen = () => (
    <AwesomeButtonSocial
      icon
      type="gplus"
      ripple
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: -120
      }}
      onPress={this.authWithGoogle}
    >
      Đăng nhập bằng Google
    </AwesomeButtonSocial>
  )

  renderLoading = () => (
    <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1" />
      <div className="sk-cube sk-cube2" />
      <div className="sk-cube sk-cube3" />
      <div className="sk-cube sk-cube4" />
      <div className="sk-cube sk-cube5" />
      <div className="sk-cube sk-cube6" />
      <div className="sk-cube sk-cube7" />
      <div className="sk-cube sk-cube8" />
      <div className="sk-cube sk-cube9" />
      <p>XBOT</p>
    </div>
  )

  render() {
    const { user, settings } = this.props;
    const { handling, email } = user;
    if (handling)
      return this.renderLoading();
    if (!email)
      return <div>{this.renderLoginScreen()}</div>;
    if (!this.workspaceReady())
      return this.renderLoading();
    return (
      <ThemeProvider theme={createMuiTheme({ palette: { type: _.get(settings, 'dark', false) ? 'dark' : 'light' } })}>
        <div style={{ marginBottom: 50 }} className={_.get(settings, 'dark', false) ? 'bp3-dark' : ''}><Workspace /></div>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  handleUpdateSettings: PropTypes.func.isRequired,
  handleUserSignedIn: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    handling: PropTypes.bool,
    activeUnit: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  catalogs: PropTypes.shape({
    tags: PropTypes.shape({
      handling: PropTypes.bool
    }),
    periods: PropTypes.shape({
      handling: PropTypes.bool
    })
  }).isRequired,
  settings: PropTypes.shape({
    dark: PropTypes.bool
  }).isRequired
};

const mapStateToProps = ({ user, catalogs, settings }) => ({
  user,
  catalogs,
  settings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  handleUserSignedIn,
  handleUpdateSettings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
