import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


const dev = false;

const config = {
  apiKey: 'AIzaSyAfpb28xpg0ojRw5NlXGIxNGBVgv1diaak',
  authDomain: 'xbot-lib.firebaseapp.com',
  databaseURL: 'https://xbot-lib.firebaseio.com',
  projectId: 'xbot-lib',
  storageBucket: 'xbot-lib.appspot.com',
  messagingSenderId: '303935137069',
  appId: '1:303935137069:web:6b0642485a3c0538199fa4'
};

const app = firebase.initializeApp(config);
app.auth().languageCode = 'vi';

const db = app.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters({
  prompt: 'select_account'
});

const deleteField = firebase.firestore.FieldValue.delete();

export {
  app,
  db,
  dev,
  googleProvider,
  deleteField
};
